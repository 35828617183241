import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import { gql } from '@apollo/client';
export var FollowOfferingDocument = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation FollowOffering($input: FollowCreateInput!) {\n    follow(input: $input) {\n      target {\n        ... on Offering {\n          following\n        }\n      }\n      errors {\n        message\n      }\n    }\n  }\n"])));
export var OfferingsSearchDocument = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query OfferingsSearch {\n    xOfferingsSearch(searchParams: {}, first: 9) {\n      nodes {\n        id\n        address {\n          state\n          city\n        }\n        badges\n        cardImageUrl\n        cardTooltipText\n        companyName\n        description\n        following\n        indexInAllOfferings\n        logoUrl\n        numOfAllOfferings\n        slug\n        state\n        tags {\n          id\n          colorHex\n          displayName\n          slug\n        }\n        terms\n      }\n    }\n  }\n"])));
export var OfferingDocument = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query Offering($slug: String!) {\n    offering(slug: $slug) {\n      id\n      companyName\n    }\n  }\n"])));
export var SavedOfferingsDocument = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query SavedOfferings($userSlug: String!) {\n    savedOfferingsForUser(userSlug: $userSlug, first: 12) {\n      nodes {\n        id\n        companyName\n      }\n    }\n  }\n"])));
export var UnfollowOfferingDocument = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation UnfollowOffering($input: FollowDeleteInput!) {\n    unfollow(input: $input) {\n      target {\n        ... on Offering {\n          following\n        }\n      }\n      errors {\n        message\n      }\n    }\n  }\n"])));